'use client'

import {useFormStatus} from 'react-dom'
import LoadingIndicator from 'components/loading/LoadingIndicator/LoadingIndicator'
import {c} from 'utils/className'
import styles from './button.module.scss'

type Props = {
  label: string
  isSmall?: boolean
  isDisabled?: boolean
  form?: string
}

const SubmitButton = ({label, isSmall, isDisabled, form}: Props) => {
  const {pending} = useFormStatus()

  const className = c(
    styles.container,
    styles.primary,
    isDisabled && styles.disabled,
    pending && styles.loading,
    isSmall && styles.small
  )

  return (
    <button className={className} type={'submit'} disabled={isDisabled || pending} form={form}>
      <span className={c(pending && styles.hidden)}>{label}</span>
      {pending && (
        <div className={styles.loadingIndicatorContainer}>
          <LoadingIndicator size='m' />
        </div>
      )}
    </button>
  )
}

export default SubmitButton
