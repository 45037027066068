import Link from 'next/link'
import React, {ForwardedRef, InputHTMLAttributes, ReactNode, forwardRef} from 'react'
import {c} from 'utils/className'
import styles from './Input.module.scss'

//prettier-ignore
interface Props
  extends Pick<
    InputHTMLAttributes<HTMLInputElement>,
    | 'name' | 'required' | 'placeholder' | 'disabled' | 'autoFocus' | 'value' | 'defaultValue' | 'pattern'
    | 'onChange' | 'inputMode' | 'onFocus' | 'onBeforeInput' | 'min' | 'max' | 'role' | 'aria-label'
  > {
  type?: 'text' | 'number' | 'email' | 'password' | 'url' | 'tel'
  label?: string
  caption?: string
  captionHref?: string
  rightElement?: ReactNode
  labelPosition?: 'left' | 'top'
  upperCase?: boolean
}

// TODO: refactor: find better way to render rightElement (icon/button). Also fix input right padding.
const Input = (
  {
    type = 'text',
    label,
    caption,
    captionHref,
    rightElement,
    labelPosition = 'top',
    disabled,
    upperCase,
    ...rest
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const captionElement = captionHref ? (
    <Link className={styles.caption} href={captionHref}>
      {caption}
    </Link>
  ) : (
    <div className={styles.caption}>{caption}</div>
  )

  return (
    <label
      className={c(
        styles.container,
        labelPosition === 'left' && styles.hasLabelLeft,
        disabled && styles.disabled
      )}>
      {label}
      <div className={styles.inputContainer}>
        <input
          className={c(
            styles.input,
            rightElement && styles.hasRightElement,
            upperCase && styles.upperCase
          )}
          type={type}
          {...rest}
          ref={ref}
        />
        {rightElement && <div className={styles.rightContainer}>{rightElement}</div>}
      </div>
      {caption && captionElement}
    </label>
  )
}

export default forwardRef(Input)
