'use client'

import {useFormState} from 'react-dom'
import Input from 'components/form/Input/Input'
import LogoH1 from 'components/LogoH1/LogoH1'
import SubmitButton from 'components/buttons/SubmitButton'
import {getT} from 'i18n/i18n'
import logIn from 'services/auth/serverActions/logIn'
import styles from './default.module.scss'

const t = getT('login')

const LogInPage = () => {
  const [error, action] = useFormState(logIn, undefined)

  return (
    <form action={action} className={styles.container}>
      <LogoH1 />
      <div className={styles.inputs}>
        <Input name='email' label={t('email')} type='email' required />
        {/* TODO: add Forgot Password as caption link: */}
        <Input name='password' label={t('password')} type='password' required />
        {error === 'invalidCredentials'
          ? t('invalid_credentials')
          : error === 'error'
          ? t('failed')
          : null}
      </div>
      <SubmitButton label={t('submit')} />
    </form>
  )
}

export default LogInPage
